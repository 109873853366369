<template>
  <div id="app">
    <transition name="slide-fade">
      <router-view />
    </transition>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  mounted() {
    // // 设置根元素的字体大小，可以根据需要进行调整
    // this.setRootFontSize();
    // // 监听窗口大小变化，动态调整字体大小
    // window.addEventListener("resize", this.setRootFontSize);
    // rem等比适配配置文件
    // 基准大小
    const baseSize = 16;
    // 设置 rem 函数
    function setRem() {
      // 当前页面屏幕分辨率相对于 1920宽的缩放比例，可根据自己需要修改
      const scale = document.documentElement.clientWidth / 1912;
      // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
      document.documentElement.style.fontSize =
        baseSize * Math.min(scale, 2) + "px";
    }
    // 初始化
    setRem();
    // 改变窗口大小时重新设置 rem
    window.onresize = function () {
      setRem();
    };
  },
  methods: {
    setRootFontSize() {
      // 获取用户的浏览器字体大小
      var userBrowserFontSize = window.getComputedStyle(
        document.documentElement
      ).fontSize;
      // console.log(userBrowserFontSize);
      var zoomLevel = window.devicePixelRatio;
      console.log(zoomLevel);
      // console.log(1,zoomLevel);
      // 根据视口宽度设置字体大小，可以根据需要进行调整
      console.log(document.documentElement.clientWidth);
      const baseSize =
        (document.documentElement.clientWidth / 1912) * 16 * zoomLevel; // 1rem等于视口宽度的1/10
      document.documentElement.style.fontSize = `${baseSize}px`;
    },
  },
};
</script>
<style lang="less">
.slide-fade-enter-active {
  transition: all 1s ease;
  animation: slide-fade-enterxx 1s ease;
}

.slide-fade-leave-active {
  transition: all 1s ease;
}

.slide-fade-leave {
  // transform: translateX(-1920px);
  opacity: 0.5;
}

@keyframes slide-fade-enterxx {
  0% {
    transform: translateX(480px);
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

html {
}
@font-face {
  font-family: "AlibabaPuHuiTi_2_55_Regular";
  src: url("./assets/AlibabaPuHuiTi-2-55-Regular.otf");
}
ul,
p,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
* {
  // font-size: 1rem;
  font-family: "AlibabaPuHuiTi_2_55_Regular";
}
*::-webkit-scrollbar {
  display: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  // padding: 20px;
  // background-color: #edecf1;
  width: 100vw;
  height: 100vh;
  // box-sizing: border-box;
  // overflow: auto;
}
</style>
