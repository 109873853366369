import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      points: '0', //胶片数量
      status: "",// vip
      duration:0, // 视频时长
    }
  },
  getters: {

  },
  mutations: {
    updateUser(state, user) {
      state.user = user
    },
    updateUserPoints(state, points) {
      state.user.points = points
    },
    // 视频时长
    updateUserDuration(state, duration) {
      state.user.duration = duration
    },
  },
  actions: {

  },
  modules: {

  }
})
