import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/newIndex',
    name: 'newIndex',
    component: () => import('@/views/newIndex.vue'),
    redirect: '/IPFile',
    children: [{
        path: '/IPFile',
        name: 'IPFile',
        component: () => import('@/views/newIndex/IPFile.vue'),
      },
      {
        path: '/FoundIP',
        name: 'FoundIP',
        component: () => import('@/views/newIndex/FoundIP.vue'),
      },
      {
        path: '/IPdetails',
        name: 'IPDetails',
        component: () => import('@/views/newIndex/IPdetails.vue'),
      },
      {
        path: '/personage',
        name: 'personage',
        component: () => import('@/views/newIndex/personage.vue'),
      },
      {
        path: '/MyWork',
        name: 'MyWork',
        component: () => import('@/views/newIndex/MyWork.vue'),
      },
      {
        path: '/account',
        name: 'account',
        component: () => import('@/views/newIndex/account.vue'),
      },
    ]
  },
  {
    path: '/newVideoCreation',
    name: 'newVideoCreation',
    component: () => import('@/views/newVideoCreation.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
  },
  {
    path: '/',
    name: 'welcomePage',
    component: () => import('@/views/welcomePage.vue'),
  },
  {
    path: '/phoneFaceRecognition',
    name: 'phoneFaceRecognition',
    component: () => import('@/views/newIndex/phoneFaceRecognition.vue'),
  }
]

const router = new VueRouter({
  routes
})

export default router
